<template>
    <div class="spare-list">
        <div class="title">备件库退货地址</div>
        <div class="content">
            <div class="item">
                <span class="span-title">联系人：</span>
                <span>{{ship_name}}</span>
            </div>
            <div class="item">
                <span class="span-title">联系电话：</span>
                <span>{{phone}}</span>
            </div>
            <div class="item">
                <span class="span-title">邮编：</span>
                <span>{{postcode}}</span>
            </div>
            <div class="item">
                <span class="span-title">退货地址：</span>
                <span>{{where_address}}&nbsp&nbsp&nbsp{{detailed_address}}</span>
            </div>
        </div>
        <div class="dialog-footer">
            <el-button type="primary" @click="edit">修改</el-button>
        </div>
    </div>
</template>

<script>
    import {tranDistributionStuSalesAddressList} from '@/utils/apis'
    export default {
        name: "List",
        data(){
            return{
                id:'',
                ship_name:'',
                phone:'',
                postcode:'',
                where_address:[],
                detailed_address:'',
            }
        },
        mounted(){
            this.getSalesAddress()
        },
        methods:{
            getSalesAddress() {
                let param = {}
                if (this.$route.query.op_id) {
                    param.op_id = this.$route.query.op_id;
                }
                if (this.$route.query.course_id) {
                    param.course_id = this.$route.query.course_id;
                }
                tranDistributionStuSalesAddressList(param).then((res) => {
                    // this.infoList.push(res.data)
                    // this.infoList.where_address=res.data.where_address
                    this.ship_name = res.data.ship_name
                    this.phone = res.data.phone
                    this.postcode = res.data.postcode
                    this.where_address = res.data.where_address
                    this.detailed_address = res.data.detailed_address
                })
            },

            //编辑
            edit(){
                if (this.$route.query.op_id) {
                    this.$router.push({
                        path: '/student/trainCenter/startTraining/studentspareparts',
                        query: {
                            detail: 'detail',
                            op_id: this.$route.query.op_id,
                            exam_module_id:this.$route.query.exam_module_id,
                            course_id:this.$route.query.course_id,
                        }
                    })
                } else {
                    this.$router.push({
                        path: '/student/exam/onlineTradeManage/studentspareparts',
                        query: {
                            detail: 'detail',
                            exam_module_id:this.$route.query.exam_module_id
                        }
                    })
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .spare-list{
        display: flex;
        flex-direction: column;
        height: 100%;
        .title{
            font-size: 20px;
            padding-left: 38px;
            line-height: 1;
            margin: 20px 0 30px;
            display: flex;
            align-items: center;
        }
        .content{
            padding: 0 70px;
            height: 100%;
            .item{
                margin-bottom:20px;
                .span-title{
                    display: inline-block;
                    text-align: right;
                    width:70px;
                }
            }
        }

        .dialog-footer{
            margin: 20px 0;
            text-align: center;
        }
    }

</style>